import React from "react"
import { useIsAuthenticated, useSignIn } from "react-auth-kit"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import {
    Alert,
    Card,
    CardHeader,
    CardBody,
    CardSubtitle,
    CardText,
    Button,
    Container,
} from "reactstrap"

import { Form, FormGroup, Input, Label, Row, Col } from "reactstrap"

const Template = (templateForm) => {
    return (
        <Container className="container-fluid mt-5">
            <Row>
                <Col xs={2}></Col>
                <Col xs={8}>
                    <Card>
                        <CardHeader tag="h1" className="bg-white text-center text-secondary fw-bold">
                            video<span className="text-primary">Node</span><span className="text-accent2">.pro</span>
                        </CardHeader>
                        <CardBody>
                            <CardText className="text-primary">
                            </CardText>
                            {/* <div className="text-center">
                                <img alt="Logo" src={logo} style={{height: '33vh'}}/>
                            </div> */}
                            {templateForm()}
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={2}></Col>
            </Row>
        </Container>
    )
}

const UserForm = (props) => {

    const formType = props.type
    const isAuthenticated = useIsAuthenticated()
    const signIn = useSignIn()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const dashboard = searchParams.get("dashboard")

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [loginError, setLoginError] = React.useState("")

    const handleEmailChange = (event) => setEmail(event.target.value)
    const handlePasswordChange = (event) => setPassword(event.target.value)

    const formInputs =  {
        'login': {
            labelText: '',
            buttonText: 'Login',
            apiPath: 'auth/login',
            requestHandler: (userCredentials) => {
                if (signIn({
                        token: userCredentials.token,
                        tokenType: "Bearer", // Token type set as Bearer
                        authState: { email: email, token: userCredentials.token, role: userCredentials.role }, 
                        expiresIn: userCredentials.tokenExpiresMinutes, // Token Expriration time, in minutes
                        refreshToken: userCredentials.refreshToken,
                        refreshTokenExpireIn: userCredentials.refreshTokenExpirationMinutes
                    }))
                {
                    // If Login Successfull, then Redirect the user to secure route
                    navigate("/dashboard")
                } else {
                    // Else, there must be some error. So, throw an error
                    alert("Error Occoured. Try Again")
                }
            }
        },
        'start': {
            labelText: 'Create administrator user',
            buttonText: 'Create user',
            apiPath: 'auth/start',
            requestHandler: () => {
                navigate("/login")
            }
        }
    }

    const requestAuth = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setLoginError("Input your email and/or password")
        } else {
            const options = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                //credentials: 'include',
                body: JSON.stringify({email: email, password: password})
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_VMIX_PROXY}/${formInputs[formType].apiPath}`, options)
                if (response.ok) {
                    setLoginError('')
                    const authResponse = await response.json()
                    formInputs[formType].requestHandler (authResponse)
                } else {
                    setLoginError("Invalid email and/or password")
                }
            } catch (ex) {
                console.error (`requestLogin exception: ${ex}`)
                setLoginError(`Error: ${ex}`)
            }
        }
    }

    const userForm = () => {
        return (<>
            <CardSubtitle className="mb-2 text-center" tag="h2">
                {formInputs[formType].labelText}
            </CardSubtitle>

            <Form onSubmit={requestAuth}>
                <FormGroup>
                    <Label
                        for="email"
                        className="text-primary"
                    >
                        Email
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        placeholder="email"
                        type="email"
                        autoComplete="username"
                        onChange={handleEmailChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label
                        for="password"
                        className="text-primary"
                    >
                        Password
                    </Label>
                    <Input
                        id="password"
                        name="password"
                        placeholder="password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                    />
                </FormGroup>
                <div className="text-center mb-1">
                    <Button type="submit" className="w-50" outline color="primary">
                        {formInputs[formType].buttonText}
                    </Button>
                </div>
                {loginError &&
                <Alert color="danger">
                        {loginError}
                </Alert>
                }
            </Form>
            </>
        )
    }

    if (isAuthenticated()) {
        // If authenticated user, then redirect to secure dashboard
        return <Navigate to={`/dashboard/${dashboard||'pro'}`} replace />
    } else {
        return Template (userForm)
    }
}

const PasswordResetForm = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [newPassword, setNewPassword] = React.useState("")
    const [repeatPassword, setRepeatPassword] = React.useState("")
    const [passwordError, setPasswordError] = React.useState("")
    const token = searchParams.get("token")

    React.useEffect(()=> {
        if (!token) {
            navigate ('/login')
        }
    }, [navigate, token])

    const handleNewPasswordChange = (event) => setNewPassword(event.target.value)
    const handleRepeatPasswordChange = (event) => setRepeatPassword(event.target.value)

    const requestPasswordReset = async (e) => {
        e.preventDefault();
        if (!newPassword || !repeatPassword) {
            setPasswordError("Password cannot be empty")
        } else if (newPassword !== repeatPassword) {
            setPasswordError("Passwords do not match")
        } else {
            const options = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
                //credentials: 'include',
                body: JSON.stringify({token: token, password: newPassword})
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_VMIX_PROXY}/auth/resetPassword`, options)
                if (response.ok) {
                    setPasswordError('')
                    navigate("/login")
                } else {
                    setPasswordError("Error reseting password")
                }
            } catch (ex) {
                console.error (`requestLogin exception: ${ex}`)
                setPasswordError(`Error: ${ex}`)
            }
        }
    }

    const passwordResetForm = () => {
        return (<>
            <CardSubtitle className="mb-2 text-muted" tag="h4">
                Reset yout password
            </CardSubtitle>

            <Form onSubmit={requestPasswordReset}>
                <FormGroup>
                    <Label for="new-password" className="text-primary">
                        New Password
                    </Label>
                    <Input
                        id="new-password"
                        placeholder="New Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={handleNewPasswordChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="repeat-password" className="text-primary">
                        Repeat Password
                    </Label>
                    <Input
                        id="repeat-password"
                        placeholder="Repeat Password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handleRepeatPasswordChange}
                    />
                </FormGroup>
                <Button type="submit">
                    Reset Password
                </Button>
                {passwordError &&
                <Alert color="danger">
                        {passwordError}
                </Alert>
                }
            </Form>
            </>
        )
    }

    return Template (passwordResetForm)
}

const Login = () => {
    
    const signIn = useSignIn()

    React.useEffect(()=> {
        if (process.env.REACT_APP_DEMO) {
            signIn({
                token: "a",
                tokenType: "Bearer", // Token type set as Bearer
                authState: { email: 'Demo', token: '', role: 1, demo: true }, 
                expiresIn: 24*60*1000, // Token Expriration time, in minutes
                refreshToken: "a",
                refreshTokenExpireIn: 24*60*1000
            })
        }
    }, [signIn])


    return <UserForm type='login'/>
    
}

const Start = () => {
    return <UserForm type='start'/>
}

const ResetPassword = () => {
    return <PasswordResetForm/>
}

export {Login, Start, ResetPassword}
