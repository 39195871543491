import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useTranslation } from "react-i18next"

const DemoNotice = props => {

    const showAnyway = props.showAnyway || false
    const { t } = useTranslation()

    const toggleModal = () => setModal(!modal)
    
    const closeModal = React.useCallback (() => {
        window.localStorage.setItem("VNP_DEMO_NOTICE", true)
        setModal(false)
        props.onClose && props.onClose()
    }, [props])

    React.useEffect(() => { 
        const handleKeyDown = (e) => { if (e.keyCode === 27) { closeModal() } } 
        window.addEventListener("keydown", handleKeyDown) 
    }, [closeModal])

    const show = () => {
        if (window.localStorage.getItem("VNP_DEMO_NOTICE") === null) {
            return true
        } else if (showAnyway) {
            return true
        }  else { 
            return false
        }
    }

    const [modal, setModal] = React.useState (show())

    const videoNodeLogo = () => {
        return (
            <span className='text-secondary fw-bold'>video<span className="text-primary">Node</span><span className="text-accent2">.pro</span></span>
        )
    }

    return (
        <Modal isOpen={modal} toggle={toggleModal} onClosed={closeModal}>
            <ModalHeader toggle={closeModal} className="bg-primary text-white">
                {t("Demo Notice")}
            </ModalHeader>
            <ModalBody>
                <b>{t("DEMO_NOTICE.welcome")}</b>
                <div className="mt-2">
                {t("DEMO_NOTICE.message")}
                </div>
                <h4 className="d-flex justify-content-center mt-3">
                <a href="mailto:info@videonode.pro" style={{textDecoration:"auto"}}>info@{videoNodeLogo()}</a>
                </h4>
                <br/>
                <div className="d-flex justify-content-end">
                    <b>{t("DEMO_NOTICE.thanks")}</b>
                </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </Modal>
    )

}

export default DemoNotice