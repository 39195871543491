import React from "react"
import { AuthProvider, createRefresh } from "react-auth-kit"
import RoutesComponent from "./Routes"
import { useTranslation } from 'react-i18next'
import './i18n';
import DemoNotice from "./components/DemoNotice"

const requestRefreshToken = async (auth) => {

    if (process.env.REACT_APP_DEMO) {
        return {
            refreshToken: "a",
            refreshTokenExpireIn: 24*60*1000
        }
    }

    const options = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        withCredentials: true,
        //credentials: 'include',
        body: JSON.stringify({refreshToken: auth.refreshToken})
    }
    const response = await fetch(`${process.env.REACT_APP_VMIX_PROXY}/auth/refresh`,options)
    if (response.status === 200) {
        const authResponse = await response.json()
        return authResponse
    } else {
        throw Error ('Invalid refresh token')
    }
}

const refreshApi = createRefresh({
    interval: 1,
    refreshApiCallback: async (param) => {
        const response = await requestRefreshToken(param)
        return {
            isSuccess: true,  
            newAuthToken: response.token,
            newAuthTokenExpireIn: response.tokenExpiresMinutes
            // You can also add new refresh token ad new user state
        }
    }
})

const DemoRibbon = () => {

    const { t } = useTranslation()

    const wrapperStyleLeft = { 
        //transform: "rotate(45deg) translateX(-30%)",
        //zIndex: 100000
    }

    return (
        
        <div className="top-0 w-100 sticky-top" style={wrapperStyleLeft}>
            <div className="text-white bg-danger w-100 text-center">{t("DEMO DASHBOARD")}</div>
        </div>
    )
}

const App = () => {

    return (

        <AuthProvider
            authName={"_auth"}
            authType={"cookie"}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "https:"}
            refresh={refreshApi}>

            {process.env.REACT_APP_DEMO && <DemoRibbon/>}
            {process.env.REACT_APP_DEMO && <DemoNotice/>}

            <RoutesComponent />
            
        </AuthProvider>
    )
    
}

export default App
