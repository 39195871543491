import React from 'react'
import { RequireAuth, useIsAuthenticated } from 'react-auth-kit'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { Login, Start, ResetPassword } from './components/Login'
const DashboardPro = React.lazy(()=> import ('./dashboards/pro/Dashboard'))
const DashboardEdu = React.lazy(()=> import ('./dashboards/edu/Edu'))
const WHIPClient = React.lazy(() => import  ('./components/clients/WHIPClient'))
const WHEPClient = React.lazy(() => import  ('./components/clients/WHEPClient'))

const RoutesComponent = () => {

  const isAuthenticated = useIsAuthenticated()
  
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={
          isAuthenticated() ? (
            <Navigate replate to={"/dashboard/pro"} />
          ) : (
            <Navigate replate to={"/login"} />
          )
        }/>
        <Route path={'/login' } element={<Login/>}/>
        <Route path={'/start' } element={<Start/>}/>
        <Route path={'/resetPassword'} element={<ResetPassword/>}/>
        <Route path={'/clients/whip/:inputId'} element={<WHIPClient/>} />
        <Route path={'/clients/whep/:outputId'} element={<WHEPClient/>} />
        <Route path={'/dashboard/pro'} element={
          <RequireAuth loginPath={'/login?dashboard=pro'}>
            <DashboardPro/>
          </RequireAuth>
        }/>
        <Route path={'/dashboard/edu'} element={
          <RequireAuth loginPath={'/login?dashboard=edu'}>
            <DashboardEdu/>
          </RequireAuth>
        }/>
        <Route path="*" element={
          <Navigate replate to={"/login"} />
        }/>
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesComponent